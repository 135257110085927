<template>
  <photo-validation-error-modal
    name="photo-unsupported-format-modal"
    :title="$t('photoValidation.title.notAnImage')"
    :error-message="$t('photoValidation.text.notAnImage')"
  />
</template>

<script>
import PhotoValidationErrorModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoValidationErrorModal';

export default {
  name: 'PhotoUnsupportedFormatModal',
  components: { PhotoValidationErrorModal }
};
</script>
