<template>
  <questionnaire-page-template
    :title="$t('makeSelfiePage.title')"
    :doctor-info="doctorInfo"
    :user-info="userInfo"
    :language-options="languageOptions"
    :language="language"
    no-footer
    no-divider
    content-centered
    basic-background
    @change-language="$emit('change-language', $event)"
  >
    <div class="lets-make-selfie-page-content questionnaire-page-template__content">
      <div class="lets-make-selfie-page-content__text u-typography-helvetica u-text u-text--s">
        {{ $t('makeSelfiePage.introText') }}
      </div>

      <div class="lets-make-selfie-page-content__photo-instruction-section">
        <photo-instructions />
      </div>

      <div class="lets-make-selfie-page-content__action-buttons selfie-page-action-buttons">
        <input
          ref="fileInput"
          class="d-none"
          type="file"
          accept="image/*"
          @change="$emit('upload-photo', $event.target.files)"
        />
        <u-button class="selfie-page-action-buttons__button" size="small" @click="uploadFile">{{
          $t('makeSelfiePage.action.uploadPhoto')
        }}</u-button>
        <u-button
          v-if="isWebCameraCompatible && !isMobile"
          class="selfie-page-action-buttons__button"
          kind="secondary"
          size="small"
          @click="$emit('take-photo')"
          >{{ $t('makeSelfiePage.action.takeAPhoto') }}</u-button
        >
      </div>
    </div>
  </questionnaire-page-template>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnairePageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnairePageTemplate';
import PhotoInstructions from '@/modules/questionnaire/pages/lets-make-selfie/PhotoInstructions.vue';

import { isTouchDevice } from '@/utils/featureDetection';

export default {
  name: 'LetsMakeASelfieTemplate',
  components: { QuestionnairePageTemplate, UButton, PhotoInstructions },
  props: {
    doctorInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMobile: isTouchDevice()
    };
  },
  computed: {
    isWebCameraCompatible() {
      return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
    }
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~universkin-shared/src/assets/styles/scss/colors.scss';
@import '~@/assets/css/common/icons.css';

.questionnaire-page-template {
  &__content {
    margin-top: 16px;
  }
}

.lets-make-selfie-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    text-align: center;
    color: $color-grey600;
    margin-bottom: 24px;
  }

  &__photo-instruction-section {
    position: relative;
    width: 312px;
    min-height: 520px;
  }

  &__action-buttons {
    margin-top: 24px;
  }
}

.selfie-page-action-buttons {
  width: fit-content;

  &__button {
    width: 100%;
    margin: 0 auto 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: $desktop-start) {
  .questionnaire-page-template {
    &__content {
      margin-top: 32px;
    }
  }

  .lets-make-selfie-page-content {
    &__text {
      display: none;
    }

    &__photo-instruction-section {
      position: relative;
      width: 700px;
      min-height: 300px;
    }
  }
}
</style>
