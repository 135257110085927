<template>
  <modal
    name="questionnaire-photo-consents-modal"
    class="questionnaire-modal-background pin-to-top-mobile-modal"
    :classes="['modal-window']"
    transition="fade"
    scrollable
    adaptive
    :click-to-close="false"
    width="630px"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container photo-consents flex-column--align-center">
      <u-icon class="photo-consents__close-icon" name="close" @click="closeModal" />

      <h1 class="photo-consents__title u-typography-helvetica u-text u-text--l">
        {{ $t('yourDataIsSafeWithMe.title') }}
      </h1>

      <div class="photo-consents__content consents-content ie-text-modal">
        <p class="consents-content__paragraph u-typography-helvetica u-text u-text--xs">
          {{ $t('consent.captureUploadStore') }}
        </p>
        <p class="consents-content__paragraph u-typography-helvetica u-text u-text--xs">
          {{ $t('consent.label.imageForClinicalResearch') }}
        </p>

        <p class="consents-content__paragraph u-typography-helvetica u-text u-text--xs">
          {{ $t('consent.imageForClinicalResearch.inSuchCase') }}
        </p>
        <div
          class="photo-consents__agreement photo-agreement ie-text-modal"
          :class="{ error: imageUseError }"
        >
          <u-checkbox
            :value="imageUse"
            :valid="!imageUseError"
            :label="$t('consent.imageForClinicalResearch.authorize')"
            class="photo-agreement__checkbox"
            @input="updateImageUseConsent"
          />
          <u-checkbox
            :value="!allowResearch"
            :disabled="!imageUse"
            :label="$t('consent.imageForClinicalResearch.object')"
            class="photo-agreement__checkbox"
            @input="updateAllowResearchConsent"
          />
        </div>

        <u-button
          kind="primary"
          size="medium"
          class="photo-consents__next-button"
          @click="resolveConsents"
        >
          {{ $t('action.agreeAndContinue') }}
        </u-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { UButton, UIcon, UCheckbox } from 'universkin-shared';

export default {
  name: 'QuestionnairePhotoConsentsModal',
  components: { UButton, UIcon, UCheckbox },
  data() {
    return {
      imageUse: false,
      allowResearch: true,
      photoConsentsResolver: null,
      imageUseError: false
    };
  },
  methods: {
    beforeOpen({ params: { onClose } }) {
      this.photoConsentsResolver = onClose;
    },
    closeModal() {
      this.photoConsentsResolver({});

      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('questionnaire-photo-consents-modal');
    },
    updateImageUseConsent(checked) {
      this.imageUseError = false;
      this.imageUse = checked;

      if (!checked) {
        this.allowResearch = true;
      }
    },
    updateAllowResearchConsent(checked) {
      this.allowResearch = !checked;
    },
    resolveConsents() {
      if (!this.imageUse) {
        this.imageUseError = true;

        return;
      }

      this.photoConsentsResolver({
        imageUse: this.imageUse,
        allowResearch: this.allowResearch
      });

      this.hideModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '~universkin-shared/src/assets/styles/scss/typography-helvetica.scss';

.photo-consents {
  position: relative;
  padding: 70px 84px 50px;

  border-radius: 10px;

  color: $color-graphite;

  &__close-icon {
    position: absolute;
    top: 25px;
    right: 25px;

    font-size: 20px;
  }

  &__title,
  &__sub-title,
  &__consent-text {
    font-weight: 500;
    line-height: 22px;
  }

  &__title {
    margin-bottom: 29px;
    font-size: 18px;
  }

  &__sub-title {
    margin: 20px 0;
    font-size: 16px;
  }

  &__content {
    margin-bottom: 15px;
    font-size: 16px;
  }

  &__next-button {
    width: 100%;
    margin: 0 auto;
  }
}

.consents-content {
  &__paragraph {
    margin-bottom: 25px;
  }
}

.photo-agreement {
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 18px;

  &__checkbox {
    font-family: var(--u-font-family-Gilroy);
    font-weight: 400;
    font-size: 12px;
  }

  &__checkbox:first-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .photo-consents {
    margin: 10px 10px 120px;
    padding: 57px 16px 22px 16px;
  }
}
</style>
