<template>
  <photo-validation-error-modal
    name="photo-too-large-file-size-modal"
    :title="$t('photoValidation.title.maxAllowableSize')"
    :error-message="$t('photoValidation.text.maxAllowableSize')"
  />
</template>

<script>
import PhotoValidationErrorModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoValidationErrorModal';

export default {
  name: 'PhotoTooLargeFileSizeModal',
  components: { PhotoValidationErrorModal }
};
</script>
