<template>
  <div>
    <lets-make-a-selfie-template
      :doctor-info="doctorInfo"
      :user-info="patientInfo"
      :language="locale"
      :language-options="languageOptions"
      :error="errorMessage"
      :loading="isLoading"
      @change-language="onChangeLanguage"
      @upload-photo="fileChanged"
      @take-photo="onTakePhoto"
    />

    <questionnaire-photo-consents-modal />
    <photo-too-large-file-size-modal />
    <photo-unsupported-format-modal />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import LetsMakeASelfieTemplate from '@/modules/questionnaire/pages/lets-make-selfie/LetsMakeASelfieTemplate';
import QuestionnairePhotoConsentsModal from '@/modules/questionnaire/components/dialogs/QuestionnairePhotoConsentsModal';
import PhotoTooLargeFileSizeModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoTooLargeFileSizeModal';
import PhotoUnsupportedFormatModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoUnsupportedFormatModal';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';

import rootTypes from '@/store/types';
import { types as questTypes } from '@/modules/questionnaire/store/types';
import { PHOTO_VALIDATION_ERROR_MODALS } from '@/modules/questionnaire/constants/photoAnalysis';

export default {
  name: 'PageLetsMakeASelfie',
  components: {
    LetsMakeASelfieTemplate,
    QuestionnairePhotoConsentsModal,
    PhotoTooLargeFileSizeModal,
    PhotoUnsupportedFormatModal
  },
  mixins: [changeLanguageMixin, doctorAndPatientInfoMixin],
  data() {
    return {
      errorMessage: '',
      isLoading: false
    };
  },
  methods: {
    ...mapMutations({
      setQuestionnaireType: questTypes.mutations.SET_QUESTIONNAIRE_TYPE,
      setAllowPhotoResearch: questTypes.mutations.SET_ALLOW_PHOTO_RESEARCH
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      savePatientPhoto: questTypes.actions.SAVE_PATIENT_PHOTO
    }),
    async fileChanged(files) {
      if (files.length === 0) {
        return;
      }

      const { imageUse, allowResearch } = await this.$asyncModal.show(
        'questionnaire-photo-consents-modal'
      );

      if (!imageUse) {
        return;
      }

      const [file] = files instanceof FileList ? files : [files];

      this.setLoading(true);
      const { error } = await this.savePatientPhoto(file);
      this.setLoading(false);

      if (PHOTO_VALIDATION_ERROR_MODALS[error]) {
        await this.$asyncModal.show(PHOTO_VALIDATION_ERROR_MODALS[error]);
        return;
      }

      this.setAllowPhotoResearch(allowResearch);

      await this.$router.replace({ name: 'WeHaveGotYourPhoto' });
    },
    onTakePhoto() {
      this.$router.replace({ name: 'TakeAPhoto' });
    }
  }
};
</script>
